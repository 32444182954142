import React from "react";

//
type DetailSummaryProps = {
  open: boolean;
  date: string;
  detail: string;
  summary: string;
};

function DetailSummary({ date, detail, summary, open }: DetailSummaryProps) {
  return (
    <details className="py-2 border-t" open={open}>
      <summary className="text-lg font-medium leading-normal grid grid-cols-6 py-2">
        <div>{date}</div>
        <div className="col-span-4">{summary}</div>
      </summary>

      <div className="grid grid-cols-6">
        <p className="text-base pt-6 pb-10 font-light col-start-2 col-span-4">
          {detail}
        </p>
      </div>
    </details>
  );
}

DetailSummary.defaultProps = {
  open: false,
  date: "xx.yy",
  summary: "summary",
  detail: "details",
};

export default DetailSummary;
