import React from "react";
import { useHistory } from "react-router-dom";

//
function ContactButton() {
  const history = useHistory();

  function handleClick() {
    history.push("/contact");
  }

  return (
    <button
      type="button"
      onClick={handleClick}
      className="text-xs pl-5 pr-4 py-4 bg-white rounded-l-full"
    >
      Kontakt
    </button>
  );
}

export default ContactButton;
