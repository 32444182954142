import React from "react";
import anime from "animejs";

// images
import background from "../../assets/img/button_background_black.svg";

//
class ClickToStart extends React.Component {
  componentDidMount() {
    var button = document.getElementById("startButton");
    if (button === null) return;

    button.style.backgroundImage = "url(" + background + ")";
    button.style.backgroundPosition = "center";
    button.style.backgroundSize = "contain";
    button.style.backgroundRepeat = "no-repeat";

    anime({
      targets: "#startButton",
      scale: 1.1,
      easing: "linear",
      loop: true,
      direction: "alternate",
      duration: 1000,
    });
  }

  handleClick = () => {
    alert("Clicked");
  };

  render() {
    return (
      <div className="px-4">
        <button
          className="text-center text-lg text-white"
          onClick={() => {
            this.handleClick();
          }}
        >
          <div className="relative w-84 h-40 flex justify-center content-center">
            <div
              id="startButton"
              className="absolute w-72 h-32 mx-auto my-auto self-center"
            ></div>
            <div
              id="title"
              className="absolute inset-auto w-56 mx-auto self-center text-base"
            >
              Nachricht senden
            </div>
          </div>
        </button>
      </div>
    );
  }
}

export default ClickToStart;
