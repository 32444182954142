import React from "react";

// images
import logo_red from "../assets/img/logos/logo_speakuplounge_red.png";

//
function BigFooter() {
  return (
    <div id="footer" className="bg-zf_gray-medium">
      <h2 className="px-4 pt-16 text-4xl leading-tight font-bold">
        Fragen?
        <br />
        Anregungen?
      </h2>

      <address className="not-italic">
        <h3 className="sr-only">Email</h3>
        <a href="mailto:hallo@speakuplounge.de">
          <p className="px-4 pt-6 underline text-3xl font-light">
            hallo@
            <br />
            speakuplounge.de
          </p>
        </a>

        <h3 className="sr-only">Phone</h3>
        <a href="tel:0371-12345632">
          <p className="px-4 pt-2 underline text-3xl font-light">
            0371 - 123 456 32
          </p>
        </a>

        <h3 className="sr-only">Address</h3>
        <p className="px-4 pt-20 font-bold text-2xl">So finden Sie uns.</p>
        <p className="px-4 pt-4 pb-4 text-xl font-light">
          Speak Up Lounge
          <br />
          Bahnhofsstraße 51
          <br />
          09111 Chemnitz
        </p>
      </address>

      <p className="px-4 pt-24 text-xl font-light">
        &copy; 2020 Speak Up Lounge
      </p>
      <div className="flex justify-center pt-12">
        <img src={logo_red} className="h-12" alt="SpeakupLounge Logo - red" />
      </div>
    </div>
  );
}

export default BigFooter;
