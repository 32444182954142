import React from "react";

// components
import ContactButton from "../components/buttons/ContactButton";
import { Helmet } from "react-helmet";

// images
import logo_white from "../assets/img/logos/logo_speakuplounge_white.png";

//
function Impressum() {
  return (
    <div>
      <Helmet>
        <title>Impressum | Zweigfabrik</title>
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <body className="bg-zf_gray-medium"></body>
      </Helmet>
      <div className="pt-8 flex justify-between">
        <div className="pl-4 self-center">
          <a href="/">
            <img
              src={logo_white}
              alt="Logo SpeakupLounge - white"
              className="h-8"
            />
          </a>
        </div>

        <ContactButton />
      </div>

      <div className="pt-40 pb-10 px-2">
        <h1 className="text-5xl font-medium">Impressum</h1>

        <h2 className="text-3xl font-medium leading-snug pt-2">
          Angaben gemäß §5 TMG
        </h2>
        <p className="font-light text-xl pt-6">
          Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam
          nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat,
          sed diam voluptua. At vero eos et accusam et justo duo dolores et ea
          rebum. Stet clita
        </p>

        <h2 className="text-3xl font-medium leading-snug pt-10">Kontakt</h2>
        <p className="font-light text-xl pt-6">
          Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam
          nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat,
          sed diam
        </p>
        <p className="font-light text-xl pt-6">
          So sieht ein Link aus:
          <br />
          <a href="/" className="text-white underline font-normal">
            Ich bin ein Link
          </a>
        </p>
      </div>
    </div>
  );
}

export default Impressum;
