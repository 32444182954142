import React from "react";
import { useHistory } from "react-router-dom";

// image
import icon from "../../assets/img/swipe/scan_back-button.png";
//
function BackIconButton() {
  const history = useHistory();

  function handleClick() {
    history.goBack();
  }

  return (
    <button type="button" onClick={handleClick}>
      <img src={icon} id="back" alt="Zurück" className="h-10" />
    </button>
  );
}

BackIconButton.defaultProps = {
  title: "Zurück",
};

export default BackIconButton;
