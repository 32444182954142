import React from "react";

// components
import BackButton from "../components/buttons/BackButton";
import SendMessage from "../components/buttons/SendMessage";
import SmallFooter from "../components/SmallFooter";
import { Helmet } from "react-helmet";

// images
import logo_black from "../assets/img/logos/logo_speakuplounge_black.png";

//
function Contact() {
  return (
    <div>
      <Helmet>
        <title>Kontakt | Zweigfabrik</title>
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <body className="bg-zf_red"></body>
      </Helmet>
      <div>
        <div id="header" className="pt-8 flex justify-between">
          <div className="pl-4 self-center">
            <a href="/">
              <img
                src={logo_black}
                alt="Logo SpeakupLounge - black"
                className="h-8"
              />
            </a>
          </div>

          <BackButton title="Schließen" />
        </div>

        <div className="pt-12 text-black">
          <h1 className="text-4xl font-bold leading-tight px-4">
            Sie haben eine Frage oder Idee?
          </h1>
          <form className="pt-16 px-4">
            <label className="block">
              <span className="text-lg">Name</span>
              <input className="block w-full bg-zf_red-dark border-zf_red-dark text-lg px-4 py-3" />
            </label>

            <label className="block pt-6">
              <span className="text-lg">Betreff</span>
              <input className="block w-full bg-zf_red-dark border-zf_red-dark text-lg px-4 py-3" />
            </label>

            <label className="block pt-6">
              <span className="text-lg">Nachricht</span>
              <textarea className="block w-full bg-zf_red-dark border-zf_red-dark text-lg px-4 py-3"></textarea>
            </label>
          </form>

          <div className="pt-8 pb-48 flex justify-center">
            <SendMessage />
          </div>
        </div>
      </div>

      <SmallFooter />
    </div>
  );
}

export default Contact;
