import React from "react";

//
function SmallFooter() {
  return (
    <div id="footer">
      <div className="mx-4 border-t-2 border-black"></div>
      <div className="flex justify-between px-4 py-5 text-base font-light">
        <a href="/impressum">Impressum</a>
        <a href="/datenschutz">Datenschutz</a>
      </div>
    </div>
  );
}

export default SmallFooter;
