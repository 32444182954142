import { TouchEntry, TouchEventType } from "./TouchEntry"
import { Point } from "@prismadelabs/prismaid/dist/model/Point"
import { DataBuilder } from "./DataBuilder"
import { DecoderResponseError, DecoderResponseSuccess, PrismaSDK } from "@prismadelabs/prismaid"
import { Strings } from "@prismadelabs/prismaid/dist/strings/Strings"

const DIST_TOL = 30

export class Z {

    private currentStep = -1
    private activeTouches = 0
    private closestP1_touch: TouchEntry | undefined
    private closestP2_touch: TouchEntry | undefined
    private recordedTouches: TouchEntry[] = []
    private p0 = new Point(0, 0)
    private p1 = new Point(0, 0)
    private p2 = new Point(0, 0)
    private p3 = new Point(0, 0)

    public z1event = () => {}
    public z2event = () => {}
    public z3event = () => {}
    public z4event = () => {}
    public zSuccessEvent = () => {}
    public zNoSuccessEvent = () => {}

    private sdk: PrismaSDK
    private strings = new Strings()

    constructor(sdk: PrismaSDK) {
        this.sdk = sdk
        sdk.getDetectionSuccessSubject().subscribe((response) => {
            console.log("It's a Z! (" + response.probability + ")")
            this.zSuccessEvent()
        })
        sdk.getDetectionErrorSubject().subscribe((response) => {
            console.log("NOT a Z! (" + response.message + ")")
            this.zNoSuccessEvent()
        })
    }

    public attachToElement(element: Element) {
        element.addEventListener(
            "touchstart",
            (event) => {
                event.preventDefault()
                this.processTouchStart(event)
            },
            false,
        )

        element.addEventListener(
            "touchmove",
            (event) => {
                event.preventDefault()
                this.processTouchMove(event)
            },
            false,
        )

        element.addEventListener(
            "touchend",
            (event) => {
                event.preventDefault()
                this.processTouchEnd(event)
            },
            false,
        )

        element.addEventListener(
            "touchcancel",
            (event) => {
                event.preventDefault()
                this.processTouchCancel()
            },
            false,
        )

        const card = document.getElementById("card");
        if (!card) return;

        const cardRect = card.getBoundingClientRect();
        const zCardSize = new Point(718, 718)
        const p0ref = new Point(214, 129)
        const p1ref = new Point(620, 129)
        const p2ref = new Point(232, 587)
        const p3ref = new Point(620, 587)
        this.p0 = new Point(cardRect.x + cardRect.width * (p0ref.x / zCardSize.x), cardRect.y + cardRect.height * (p0ref.y / zCardSize.y))
        this.p1 = new Point(cardRect.x + cardRect.width * (p1ref.x / zCardSize.x), cardRect.y + cardRect.height * (p1ref.y / zCardSize.y))
        this.p2 = new Point(cardRect.x + cardRect.width * (p2ref.x / zCardSize.x), cardRect.y + cardRect.height * (p2ref.y / zCardSize.y))
        this.p3 = new Point(cardRect.x + cardRect.width * (p3ref.x / zCardSize.x), cardRect.y + cardRect.height * (p3ref.y / zCardSize.y))
    }

    private processTouchStart(event: any) {
        this.activeTouches += event.changedTouches.length
        for (const touch of event.changedTouches as Touch[]) {
            const dist = this.distance(this.p0, touch.pageX, touch.pageY)
            if (dist < DIST_TOL) {
                this.currentStep = 0
                this.z1event()
            }
        }
    }

    private processTouchMove(event: any) {
        if (this.activeTouches < 2) {
            return
        }
        for (const touch of event.changedTouches as Touch[]) {
            const touchEntry = new TouchEntry(touch.identifier, TouchEventType.move, touch.pageX, touch.pageY, touch.radiusX, Date.now())
            this.recordedTouches.push(touchEntry)
            const dist_p1 = this.distance(this.p1, touch.pageX, touch.pageY)
            const dist_p2 = this.distance(this.p2, touch.pageX, touch.pageY)
            const dist_p3 = this.distance(this.p3, touch.pageX, touch.pageY)
            if (dist_p1 < DIST_TOL && this.currentStep === 0) {
                this.currentStep = 1
                this.z2event()
            }
            else if (dist_p2 < DIST_TOL && this.currentStep === 1) {
                this.currentStep = 2
                this.z3event()
                this.closestP2_touch = touchEntry
            }
            else if (dist_p3 < DIST_TOL && this.currentStep === 2) {
                this.currentStep = 3
                this.z4event()
                this.evaluateTouches()

                /*if (out.length < 100) {
                    continue
                }
                const slowVal = (out[42] + out[43] + out[44] + out[45] + out[46]) / 5
                const fastVal = (out[55] + out[56] + out[57] + out[58] + out[59] + out[60] + out[61] + out[62] + out[63]) / 9
                if (slowVal < 0.5 && fastVal > 0.7) {
                    console.log("It's a Z! (" + slowVal + ":" + fastVal + ")")
                    this.zSuccessEvent()
                } else {
                    console.log("NOT a Z! (" + slowVal + ":" + fastVal + ")")
                    this.zNoSuccessEvent()
                }*/
                break
            }
            if (dist_p1 < DIST_TOL && this.currentStep === 1) {
                this.closestP1_touch = touchEntry
            }
        }
    }

    private processTouchEnd(event: any) {
        this.activeTouches -= event.changedTouches.length
        if (this.activeTouches <= 0) {
            this.processTouchCancel()
        }
    }

    private processTouchCancel() {
        this.currentStep = -1
        this.activeTouches = 0
        this.closestP1_touch = undefined
        this.closestP2_touch = undefined
        this.recordedTouches = []
    }

    private distance(object: Point, x: number, y: number) {
        return Math.sqrt(Math.pow(object.x - x, 2) + Math.pow(object.y - y, 2))
    }

    private async evaluateTouches() {
        //console.log("P1: (" + this.p1.x + ":" + this.p1.y + ")")
        //console.log("P2: (" + this.p2.x + ":" + this.p2.y + ")")
        //console.log("Evaluating (" + this.closestP1_touch.x + ":" + this.closestP1_touch.y + ") - (" + this.closestP2_touch.x + ":" + this.closestP2_touch.y + ")")
        if (!this.closestP1_touch || !this.closestP2_touch) {
            return []
        }
        const filteredTouches = []
        const fakeHoldingFinger = new TouchEntry(1234567, TouchEventType.start, 1, 1, 1, this.closestP1_touch.timestamp)
        filteredTouches.push(fakeHoldingFinger)
        let firstSeen = false
        for (const touch of this.recordedTouches) {
            if (touch.id !== this.closestP1_touch.id) {
                continue
            }
            if (touch.x === this.closestP1_touch.x && touch.y === this.closestP1_touch.y) {
                firstSeen = true
            }
            if (firstSeen) {
                filteredTouches.push(touch)
            }
            if (touch.x === this.closestP2_touch.x && touch.y === this.closestP2_touch.y) {
                break
            }
        }

        const touchDataFlat: TouchEntry[] = []
        filteredTouches.forEach((touchEntry) => {
            touchDataFlat.push(touchEntry)
        })

        const data = new DataBuilder(this.sdk)
                .enableCheckSumAndSignature()
                .setTouchData(touchDataFlat)
                .buildJSONStringify()

        const userAgent = navigator.userAgent
        const options = {
            body: data,
            headers: {
                "Content-Encoding": "identity",
                "Content-Type": "application/json",
                "user-agent": `${userAgent}`,
                "x-api-key": `${this.sdk.getAPIKey()}`,
            },
            method: "post",
        }

        const url: string = "/decode"
        const response = await this.sdk.getDataManager().fetch(url, options)

        this.processResponse(response.status, response.data)
    }

    protected processResponse(status: number, response: any) {
        // console.log("processResponse status:", status, "response:", response)
        switch (status) {
            case 200:
                this.processSuccess(response)
                break

            default:
                this.processError(response)
                break
        }
    }

    protected processSuccess(response: any) {
        const decoderResponse = new DecoderResponseSuccess(response)
        this.sdk.getDetection().success(decoderResponse)
    }

    protected processError(response: any) {
        const decoderResponse = new DecoderResponseError(response, this.strings)
        this.sdk.getDetection().error(decoderResponse)
    }

    private getNormalizedData(touchStream: TouchEntry[], numVals: number, intervals: number[]): number[] {
        const touchStreamTemp = [...touchStream]
        if (touchStreamTemp.length < 1) {
            return []
        }
        touchStreamTemp.sort(
            (left, right): number => {
                return left.y - right.y
            },
        )
        const minLoc = touchStreamTemp[0].y
        const maxLoc = touchStreamTemp[touchStreamTemp.length - 1].y
        let minTime = 100000000000000000.0
        touchStreamTemp.forEach((touch) => {
            if (touch.timestamp < minTime) {
                minTime = touch.timestamp
            }
        })
        const refData: TouchEntry[] = []
        touchStreamTemp.forEach((touch) => {
            refData.push(new TouchEntry(touch.id, touch.event, touch.x, touch.y, touch.radius, touch.timestamp))
        })
        const interval = (maxLoc - minLoc) / numVals
        const speedData: number[] = []
        for (let sectionId = 0; sectionId < numVals; sectionId++) {
            const leftLoc = minLoc + interval * sectionId
            const leftTime = this.getTimeAtLoc(refData, leftLoc)
            const rightLoc = minLoc + interval * (sectionId + 1)
            const rightTime = this.getTimeAtLoc(refData, rightLoc)
            const timeDiff = Math.abs(rightTime - leftTime)
            const locDiff = rightLoc - leftLoc
            const avgSpeed = locDiff / timeDiff
            speedData.push(avgSpeed)
            intervals.push(leftLoc)
        }

        const maxSpeed = Math.max(...speedData)
        const normalizedData: number[] = []
        speedData.forEach((speed) => {
            normalizedData.push(speed / maxSpeed)
        })

        return normalizedData
    }

    private getTimeAtLoc(data: TouchEntry[], loc: number): number {
        let match: TouchEntry | undefined
        let leftMatch: TouchEntry | undefined
        let rightMatch: TouchEntry | undefined
        data.forEach((touch) => {
            if (touch.y === loc) {
                match = touch
            }
            if (touch.y < loc) {
                leftMatch = touch
            }
            if (!rightMatch && touch.y > loc) {
                rightMatch = touch
            }
        })
        if (match) {
            return match.timestamp
        }
        if (leftMatch && rightMatch) {
            const timeDiff = rightMatch.timestamp - leftMatch.timestamp
            const leftFraction = (loc - leftMatch.y) / (rightMatch.y - leftMatch.y)
            return leftMatch.timestamp + leftFraction * timeDiff
        }
        return 0
    }

}
