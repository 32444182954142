import React from "react";
import { useHistory } from "react-router-dom";

//
type BackButtonProps = {
  title: string;
};

function BackButton({ title }: BackButtonProps) {
  const history = useHistory();

  function handleClick() {
    history.goBack();
  }

  return (
    <button
      type="button"
      onClick={handleClick}
      className="text-xs pl-5 pr-4 py-4 bg-white rounded-l-full"
    >
      {title}
    </button>
  );
}

BackButton.defaultProps = {
  title: "Zurück",
};

export default BackButton;
