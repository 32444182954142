import React from "react";
import ReactDOM from "react-dom";
import { Route, BrowserRouter as Router, Switch } from "react-router-dom";

// fonts
import "./assets/fonts/Poppins/Poppins-Black.ttf";
import "./assets/fonts/Poppins/Poppins-Bold.ttf";
import "./assets/fonts/Poppins/Poppins-ExtraBold.ttf";
import "./assets/fonts/Poppins/Poppins-ExtraLight.ttf";
import "./assets/fonts/Poppins/Poppins-Light.ttf";
import "./assets/fonts/Poppins/Poppins-Medium.ttf";
import "./assets/fonts/Poppins/Poppins-Regular.ttf";
import "./assets/fonts/Poppins/Poppins-SemiBold.ttf";
import "./assets/fonts/Poppins/Poppins-Thin.ttf";

// css
import "./index.css";

// pages
import Home from "./pages/Home";
import Swipe from "./pages/Swipe";
import Verify from "./pages/Verify";
import Contact from "./pages/Contact";
import Impressum from "./pages/Impressum";
import NotFound from "./pages/NotFound";

import * as serviceWorker from "./serviceWorker";

//
const routing = (
  <Router>
    <div>
      <Switch>
        <Route exact path="/" component={Home} />
        <Route path="/swipe" component={Swipe} />
        <Route path="/verify" component={Verify} />
        <Route path="/contact" component={Contact} />
        <Route path="/impressum" component={Impressum} />
        <Route component={NotFound} />
      </Switch>
    </div>
  </Router>
);

ReactDOM.render(routing, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
