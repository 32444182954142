import React from "react";
// TODO @types
import DeviceOrientation, { Orientation } from "react-screen-orientation";

// components
import { Helmet } from "react-helmet";
import ZScreen from "../components/ZScreen";
import NotSupportedModal from "../components/modals/NotSupportedModal";
import BackIconButton from "../components/buttons/BackIconButton";

// functions
import { disableBodyScroll, clearAllBodyScrollLocks } from "body-scroll-lock";

// images
import rotateDevice from "../assets/img/alerts/rotate-device.png";

//
type SwipeProps = {};
type SwipeStates = {
  orientation: string;
  //   isSupported: boolean;
  deviceIsSupported: boolean;
  browserIsSupported: boolean;
};

class Swipe extends React.Component<SwipeProps, SwipeStates> {
  swipeElement!: HTMLElement;
  overlay!: HTMLElement;
  click: any;

  constructor(props: SwipeProps) {
    super(props);

    this.state = {
      orientation: "portrait",
      //   isSupported: true,
      deviceIsSupported: true,
      browserIsSupported: true,
    };
  }

  componentDidMount() {
    this.swipeElement = document.querySelector<HTMLElement>("#swipe")!;
    disableBodyScroll(this.swipeElement);

    this.overlay = document.querySelector<HTMLElement>("#overlay")!;
    disableBodyScroll(this.overlay);
  }

  componentWillUnmount() {
    clearAllBodyScrollLocks();
  }

  clickTemp = (click: any) => {
    this.clickTemp = click;
  };

  setDeviceIsSupported = (deviceIsSupported: boolean) => {
    this.setState({ deviceIsSupported: deviceIsSupported });
  };

  setBrowserIsSupported = (browserIsSupported: boolean) => {
    this.setState({ browserIsSupported: browserIsSupported });
  };

  showNotSupportedModal = (): boolean => {
    if (!this.state.deviceIsSupported) return true;
    if (!this.state.browserIsSupported) return true;

    return false;
  };

  render() {
    return (
      <DeviceOrientation
        lockOrientation={"portrait"}
        onLockOrientation={(success: any) => {}}
        onOrientationChange={(orientation: any) => {
          this.setState({ orientation: orientation });
          console.log("device orientation changed to: ", orientation);
        }}
      >
        {/* Will only be in DOM in portrait */}
        <Orientation orientation="portrait" alwaysRender={false}>
          <div id="swipe">
            <Helmet>
              <title>Swipe | Zweigfabrik</title>
              <meta
                name="viewport"
                content="width=device-width, initial-scale=1"
              />
            </Helmet>

            <ZScreen
              setTemp={this.clickTemp}
              setDeviceSupport={this.setDeviceIsSupported}
              setBrowserSupport={this.setBrowserIsSupported}
              orientation={this.state.orientation}
            />

            <div
              id="overlay"
              className="absolute top-0 w-screen flex justify-between px-4 py-4"
              style={{ touchAction: "none" }}
            >
              <BackIconButton />
              {/* <button
            id="tempButton"
            className="bg-blue-500 text-white px-3 py-2 rounded"
            type="button"
            onClick={() => {
              // FIXME check if it works
              // this.clickTemp();
              this.clickTemp(true);
            }}
          >
            tempAction
          </button> */}
              {this.showNotSupportedModal() ? (
                <NotSupportedModal
                  deviceIsSupported={this.state.deviceIsSupported}
                  browserIsSupported={this.state.browserIsSupported}
                />
              ) : null}
            </div>
          </div>
        </Orientation>

        {/* Will only be in DOM in landscape */}
        <Orientation orientation="landscape" alwaysRender={false}>
          <div>
            <div className="flex items-center justify-center mt-8">
              <img
                src={rotateDevice}
                alt="Please rotate your device."
                className="max-h-1/2-screen"
              />
            </div>
          </div>
        </Orientation>
      </DeviceOrientation>
    );
  }
}

export default Swipe;
