import React from "react";

// components
import ContactButton from "../components/buttons/ContactButton";
import BigFooter from "../components/BigFooter";
import DetailSummary from "../components/DetailSummary";
import { Helmet } from "react-helmet";

// images
import logo_white from "../assets/img/logos/logo_speakuplounge_white.png";
import hero_image from "../assets/img/hero-image.png";

//
function Verify() {
  return (
    <div>
      <Helmet>
        <title>Verify | Zweigfabrik</title>
        <meta name="viewport" content="width=device-width, initial-scale=1" />
      </Helmet>
      <div className="bg-zf_red">
        <div className="pt-8 flex justify-between">
          <div className="pl-4 self-center">
            <a href="/">
              <img
                src={logo_white}
                alt="Logo SpeakupLounge - white"
                className="h-8"
              />
            </a>
          </div>

          <ContactButton />
        </div>

        <div className="pt-10 text-white">
          <img
            src={hero_image}
            alt="a hand holding a lighbulb"
            className="px-4 mx-auto"
          />

          <h1 className="px-4 pt-20">
            <div className="text-xs uppercase font-light">Die Zukunft ist</div>
            <div className="pt-4 text-5xl font-medium leading-none">
              Näher als
              <br />
              Sie denken
            </div>
          </h1>
          <p className="text-lg pt-8 px-4 font-light">
            Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam
            nonumy eirmod tempor invidunt ut labore et dolore.
          </p>
        </div>

        <div className="pt-16 px-4 text-white">
          <div className="border-t border-b divide-y font-light">
            <p className="uppercase text-xl px-2">Lorem ipsum</p>
            <p className="uppercase text-xl px-2">Sadipscing Elitr</p>
            <p className="uppercase text-xl px-2">Nonumy Eirmod</p>
            <p className="uppercase text-xl px-2">Invidunt Ut Labore</p>
            <p className="uppercase text-xl px-2">Magna Aliquyam</p>
          </div>
        </div>

        <div>
          <h2 className="px-4 pt-20 text-white">
            <div className="text-xs uppercase font-light">Upcoming</div>
            <div className="pt-4 text-5xl font-medium leading-tight">
              New
              <br />
              Events
            </div>
          </h2>

          <div className="pt-24 pb-20 px-4 text-white">
            <DetailSummary
              open
              date="01.10"
              summary="Lorem ipsum dolor sit amet, consetetur"
              detail="Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam"
            />

            <DetailSummary
              date="01.10"
              summary="Lorem ipsum dolor sit amet, consetetur"
              detail="Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam"
            />

            <DetailSummary
              date="01.10"
              summary="Lorem ipsum dolor sit amet, consetetur"
              detail="Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam"
            />

            <DetailSummary
              date="01.10"
              summary="Lorem ipsum dolor sit amet, consetetur"
              detail="Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam"
            />
          </div>
        </div>
      </div>

      <BigFooter />
    </div>
  );
}

export default Verify;
