import React from "react";

// components
import ContactButton from "../components/buttons/ContactButton";
import BigFooter from "../components/BigFooter";
import { Helmet } from "react-helmet";
import ClickToStart from "../components/buttons/ClickToStart";

// images
import logo_white from "../assets/img/logos/logo_speakuplounge_white.png";
import hero_image_small from "../assets/img/hero-image_small.png";

function Home() {
  return (
    <div>
      <Helmet>
        <title>Zweigfabrik</title>
        <meta name="viewport" content="width=device-width, initial-scale=1" />
      </Helmet>
      <div
        className="bg-auto bg-cover bg-center"
        style={{ backgroundImage: `url(${hero_image_small})` }}
      >
        <div id="header" className="pt-8 flex justify-between">
          <div className="pl-4 self-center">
            <a href="/">
              <img
                src={logo_white}
                alt="Logo SpeakupLounge - white"
                className="h-8"
              />
            </a>
          </div>

          <ContactButton />
        </div>

        <div className="pt-40 text-white">
          <h1 className="text-5xl font-medium px-4">Speak Up!</h1>
          <p className="text-xl pt-1 px-4 font-light leading-snug">
            Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam
            nonumy eirmod tempor invidunt ut.
          </p>
          <div className="pt-16 pb-24">
            <ClickToStart />
          </div>
        </div>
      </div>

      <BigFooter />
    </div>
  );
}

export default Home;
